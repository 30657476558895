export default ({ app, $device, req, $config, route, i18n }) => {
    //
    //
    // Find out host url from x-forwarded-host header (must be configured in apache properly)
    let host;

    if (process.server) {
        host = req.headers['x-forwarded-host'];
    } else {
        host = window.location.origin;
    }

    if ($config.nodeENV !== 'development' || !/^https/.test(host)) {
        host = 'https://' + host;
    }

    //
    //
    // Add meta tags
    const meta = [];

    meta.push({
        hid: 'description',
        name: 'description',
        content: i18n.t('homepage.hero.fallback_title'),
    });

    meta.push({
        hid: 'og:description',
        property: 'og:description',
        content: i18n.t('homepage.hero.fallback_title'),
    });

    meta.push({
        property: 'og:url',
        content: $config.baseURL + route.fullPath,
    });

    meta.push({
        hid: 'og:image',
        property: 'og:image',
        content: `${host}/og-image.jpg`,
    });

    meta.push({
        hid: 'og:image:url',
        property: 'og:image:url',
        content: `${host}/og-image.jpg`,
    });

    meta.push({
        property: 'og:image:type',
        content: 'image/jpg',
    });

    meta.push({
        name: 'robots',
        hid: 'robots',
        content: $config.nuxtENV === 'production' && host && !/melkweg|mlkwg/.test(host) ? 'all' : 'noindex, nofollow',
    });

    //
    //
    // Add script tags
    const script = [];

    if (process.env.NUXT_NODE_ENV === 'production' && !$device.isCrawler) {
        const SCRIPT_PATHS = ['/scripts/hotjar.js'];

        SCRIPT_PATHS.forEach((src) => {
            script.push({
                src,
                async: true,
            });
        });
    }

    script.push({
        type: 'application/ld+json',
        json: {
            '@context': 'http://schema.org',
            '@type': 'Dentist',
            name: 'Naturadent Esztétikai és Lézerfogászat',
            image: 'og-image.jpg',
            address: {
                '@type': 'PostalAddress',
                streetAddress: 'Váci utca 81.',
                addressLocality: 'Budapest',
                addressRegion: 'HU',
                postalCode: '1056',
                addressCountry: 'HU',
            },
            geo: {
                '@type': 'GeoCoordinates',
                latitude: 47.48814729202853,
                longitude: 19.056708557008506,
            },
            telephone: '+36 1 789 0690',
            openingHours: ['Mo-Th 08:00-20:00', 'Fr 08:00-15:00'],
            priceRange: '$$',
            url: 'https://naturadent.hu',
            email: 'info@naturadent.hu',
        },
    });

    app.head.script.push(...script);
    app.head.meta.push(...meta);
};
