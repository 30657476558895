import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _9ea414ba = () => interopDefault(import('../pages/privacy-policy.vue' /* webpackChunkName: "pages/privacy-policy" */))
const _5fb2f66a = () => interopDefault(import('../pages/pricing.vue' /* webpackChunkName: "pages/pricing" */))
const _5f2adc9e = () => interopDefault(import('../pages/blog/index.vue' /* webpackChunkName: "pages/blog/index" */))
const _11128529 = () => interopDefault(import('../pages/forgot-password.vue' /* webpackChunkName: "pages/forgot-password" */))
const _0d472714 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _49a56680 = () => interopDefault(import('../pages/terms-and-conditions.vue' /* webpackChunkName: "pages/terms-and-conditions" */))
const _329a6b0f = () => interopDefault(import('../pages/payment-methods.vue' /* webpackChunkName: "pages/payment-methods" */))
const _aa07825a = () => interopDefault(import('../pages/dental-problems.vue' /* webpackChunkName: "pages/dental-problems" */))
const _42668cde = () => interopDefault(import('../pages/quality-guarantee.vue' /* webpackChunkName: "pages/quality-guarantee" */))
const _883abb62 = () => interopDefault(import('../pages/appointment-booking.vue' /* webpackChunkName: "pages/appointment-booking" */))
const _48cf81c8 = () => interopDefault(import('../pages/implant-oral-surgery.vue' /* webpackChunkName: "pages/implant-oral-surgery" */))
const _b926c352 = () => interopDefault(import('../pages/invisalign-orthodontic-treatment.vue' /* webpackChunkName: "pages/invisalign-orthodontic-treatment" */))
const _0cf161bc = () => interopDefault(import('../pages/change-password.vue' /* webpackChunkName: "pages/change-password" */))
const _8d000d10 = () => interopDefault(import('../pages/legal-notice.vue' /* webpackChunkName: "pages/legal-notice" */))
const _0543bdc4 = () => interopDefault(import('../pages/contact.vue' /* webpackChunkName: "pages/contact" */))
const _44200898 = () => interopDefault(import('../pages/search.vue' /* webpackChunkName: "pages/search" */))
const _06719df5 = () => interopDefault(import('../pages/unsubscribe.vue' /* webpackChunkName: "pages/unsubscribe" */))
const _6b898726 = () => interopDefault(import('../pages/works.vue' /* webpackChunkName: "pages/works" */))
const _49d8dd6d = () => interopDefault(import('../pages/services/index.vue' /* webpackChunkName: "pages/services/index" */))
const _448201ba = () => interopDefault(import('../pages/premium-dental-care.vue' /* webpackChunkName: "pages/premium-dental-care" */))
const _0eaa1202 = () => interopDefault(import('../pages/account/referral-program.vue' /* webpackChunkName: "pages/account/referral-program" */))
const _e27b975e = () => interopDefault(import('../pages/account/settings.vue' /* webpackChunkName: "pages/account/settings" */))
const _b2cd353c = () => interopDefault(import('../pages/account/appointments.vue' /* webpackChunkName: "pages/account/appointments" */))
const _467a86d6 = () => interopDefault(import('../pages/account/dashboard.vue' /* webpackChunkName: "pages/account/dashboard" */))
const _d12b3d30 = () => interopDefault(import('../pages/about/introduction.vue' /* webpackChunkName: "pages/about/introduction" */))
const _4b75c662 = () => interopDefault(import('../pages/about/career/index.vue' /* webpackChunkName: "pages/about/career/index" */))
const _a0b83184 = () => interopDefault(import('../pages/about/trainings/index.vue' /* webpackChunkName: "pages/about/trainings/index" */))
const _26110501 = () => interopDefault(import('../pages/about/dentists/index.vue' /* webpackChunkName: "pages/about/dentists/index" */))
const _a8fbc7fc = () => interopDefault(import('../pages/about/testimonials.vue' /* webpackChunkName: "pages/about/testimonials" */))
const _7fb64501 = () => interopDefault(import('../pages/about/career/preview/_job_preview.vue' /* webpackChunkName: "pages/about/career/preview/_job_preview" */))
const _3d9d9db2 = () => interopDefault(import('../pages/about/trainings/preview/_training_preview.vue' /* webpackChunkName: "pages/about/trainings/preview/_training_preview" */))
const _4fe67391 = () => interopDefault(import('../pages/about/career/_job.vue' /* webpackChunkName: "pages/about/career/_job" */))
const _6fae5285 = () => interopDefault(import('../pages/about/dentists/_dentist.vue' /* webpackChunkName: "pages/about/dentists/_dentist" */))
const _60d34885 = () => interopDefault(import('../pages/about/notifications/_notification.vue' /* webpackChunkName: "pages/about/notifications/_notification" */))
const _c3fb39b6 = () => interopDefault(import('../pages/about/trainings/_training.vue' /* webpackChunkName: "pages/about/trainings/_training" */))
const _157ceb18 = () => interopDefault(import('../pages/blog/preview/_article_preview.vue' /* webpackChunkName: "pages/blog/preview/_article_preview" */))
const _10fec0f0 = () => interopDefault(import('../pages/about/_content.vue' /* webpackChunkName: "pages/about/_content" */))
const _c70e2bb0 = () => interopDefault(import('../pages/blog/_article.vue' /* webpackChunkName: "pages/blog/_article" */))
const _b2951d24 = () => interopDefault(import('../pages/services/_service/index.vue' /* webpackChunkName: "pages/services/_service/index" */))
const _2e9d235e = () => interopDefault(import('../pages/services/_service/_price.vue' /* webpackChunkName: "pages/services/_service/_price" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/adatvedelmi-nyilatkozat",
    component: _9ea414ba,
    name: "privacy-policy___hu"
  }, {
    path: "/araink",
    component: _5fb2f66a,
    name: "pricing___hu"
  }, {
    path: "/blog",
    component: _5f2adc9e,
    name: "blog___hu"
  }, {
    path: "/elfelejtett-jelszo",
    component: _11128529,
    name: "forgot-password___hu"
  }, {
    path: "/en",
    component: _0d472714,
    name: "index___en"
  }, {
    path: "/felhasznalasi-feltetelek",
    component: _49a56680,
    name: "terms-and-conditions___hu"
  }, {
    path: "/fizetesi-lehetosegek",
    component: _329a6b0f,
    name: "payment-methods___hu"
  }, {
    path: "/fogaszati-panaszok",
    component: _aa07825a,
    name: "dental-problems___hu"
  }, {
    path: "/garancia",
    component: _42668cde,
    name: "quality-guarantee___hu"
  }, {
    path: "/idopontfoglalas",
    component: _883abb62,
    name: "appointment-booking___hu"
  }, {
    path: "/implantatum-szajsebeszet",
    component: _48cf81c8,
    name: "implant-oral-surgery___hu"
  }, {
    path: "/invisalign-fogszabalyozas",
    component: _b926c352,
    name: "invisalign-orthodontic-treatment___hu"
  }, {
    path: "/jelszovaltoztatas",
    component: _0cf161bc,
    name: "change-password___hu"
  }, {
    path: "/jogi-nyilatkozat",
    component: _8d000d10,
    name: "legal-notice___hu"
  }, {
    path: "/kapcsolat",
    component: _0543bdc4,
    name: "contact___hu"
  }, {
    path: "/kereses",
    component: _44200898,
    name: "search___hu"
  }, {
    path: "/leiratkozas",
    component: _06719df5,
    name: "unsubscribe___hu"
  }, {
    path: "/munkaink",
    component: _6b898726,
    name: "works___hu"
  }, {
    path: "/szolgaltatasok",
    component: _49d8dd6d,
    name: "services___hu"
  }, {
    path: "/en/appointment-booking",
    component: _883abb62,
    name: "appointment-booking___en"
  }, {
    path: "/en/blog",
    component: _5f2adc9e,
    name: "blog___en"
  }, {
    path: "/en/change-password",
    component: _0cf161bc,
    name: "change-password___en"
  }, {
    path: "/en/contact",
    component: _0543bdc4,
    name: "contact___en"
  }, {
    path: "/en/dental-problems",
    component: _aa07825a,
    name: "dental-problems___en"
  }, {
    path: "/en/forgot-password",
    component: _11128529,
    name: "forgot-password___en"
  }, {
    path: "/en/invisalign-orthodontic-treatment",
    component: _b926c352,
    name: "invisalign-orthodontic-treatment___en"
  }, {
    path: "/en/legal-notice",
    component: _8d000d10,
    name: "legal-notice___en"
  }, {
    path: "/en/payment-methods",
    component: _329a6b0f,
    name: "payment-methods___en"
  }, {
    path: "/en/premium-dental-care",
    component: _448201ba,
    name: "premium-dental-care___en"
  }, {
    path: "/en/pricing",
    component: _5fb2f66a,
    name: "pricing___en"
  }, {
    path: "/en/privacy-policy",
    component: _9ea414ba,
    name: "privacy-policy___en"
  }, {
    path: "/en/quality-guarantee",
    component: _42668cde,
    name: "quality-guarantee___en"
  }, {
    path: "/en/search",
    component: _44200898,
    name: "search___en"
  }, {
    path: "/en/services",
    component: _49d8dd6d,
    name: "services___en"
  }, {
    path: "/en/terms-and-conditions",
    component: _49a56680,
    name: "terms-and-conditions___en"
  }, {
    path: "/en/unsubscribe",
    component: _06719df5,
    name: "unsubscribe___en"
  }, {
    path: "/en/works",
    component: _6b898726,
    name: "works___en"
  }, {
    path: "/profilom/ajalnasok",
    component: _0eaa1202,
    name: "account-referral-program___hu"
  }, {
    path: "/profilom/beallitasok",
    component: _e27b975e,
    name: "account-settings___hu"
  }, {
    path: "/profilom/idopontjaim",
    component: _b2cd353c,
    name: "account-appointments___hu"
  }, {
    path: "/profilom/vezerlopult",
    component: _467a86d6,
    name: "account-dashboard___hu"
  }, {
    path: "/rolunk/bemutatkozas",
    component: _d12b3d30,
    name: "about-introduction___hu"
  }, {
    path: "/rolunk/karrier",
    component: _4b75c662,
    name: "about-career___hu"
  }, {
    path: "/rolunk/kepzeseink",
    component: _a0b83184,
    name: "about-trainings___hu"
  }, {
    path: "/rolunk/orvosaink",
    component: _26110501,
    name: "about-dentists___hu"
  }, {
    path: "/rolunk/velemenyek",
    component: _a8fbc7fc,
    name: "about-testimonials___hu"
  }, {
    path: "/en/about/career",
    component: _4b75c662,
    name: "about-career___en"
  }, {
    path: "/en/about/dentists",
    component: _26110501,
    name: "about-dentists___en"
  }, {
    path: "/en/about/introduction",
    component: _d12b3d30,
    name: "about-introduction___en"
  }, {
    path: "/en/about/testimonials",
    component: _a8fbc7fc,
    name: "about-testimonials___en"
  }, {
    path: "/en/about/trainings",
    component: _a0b83184,
    name: "about-trainings___en"
  }, {
    path: "/en/account/appointments",
    component: _b2cd353c,
    name: "account-appointments___en"
  }, {
    path: "/en/account/dashboard",
    component: _467a86d6,
    name: "account-dashboard___en"
  }, {
    path: "/en/account/referral-program",
    component: _0eaa1202,
    name: "account-referral-program___en"
  }, {
    path: "/en/account/settings",
    component: _e27b975e,
    name: "account-settings___en"
  }, {
    path: "/en/about/career/preview/:job_preview",
    component: _7fb64501,
    name: "about-career-preview-job_preview___en"
  }, {
    path: "/en/about/trainings/preview/:training_preview",
    component: _3d9d9db2,
    name: "about-trainings-preview-training_preview___en"
  }, {
    path: "/en/about/career/:job",
    component: _4fe67391,
    name: "about-career-job___en"
  }, {
    path: "/en/about/dentists/:dentist",
    component: _6fae5285,
    name: "about-dentists-dentist___en"
  }, {
    path: "/en/about/notifications/:notification",
    component: _60d34885,
    name: "about-notifications-notification___en"
  }, {
    path: "/en/about/trainings/:training",
    component: _c3fb39b6,
    name: "about-trainings-training___en"
  }, {
    path: "/en/blog/preview/:article_preview",
    component: _157ceb18,
    name: "blog-preview-article_preview___en"
  }, {
    path: "/rolunk/karrier/preview/:job_preview",
    component: _7fb64501,
    name: "about-career-preview-job_preview___hu"
  }, {
    path: "/rolunk/kepzeseink/preview/:training_preview",
    component: _3d9d9db2,
    name: "about-trainings-preview-training_preview___hu"
  }, {
    path: "/blog/preview/:article_preview",
    component: _157ceb18,
    name: "blog-preview-article_preview___hu"
  }, {
    path: "/en/about/:content",
    component: _10fec0f0,
    name: "about-content___en"
  }, {
    path: "/en/blog/:article",
    component: _c70e2bb0,
    name: "blog-article___en"
  }, {
    path: "/en/services/:service",
    component: _b2951d24,
    name: "services-service___en"
  }, {
    path: "/rolunk/ertesitesek/:notification",
    component: _60d34885,
    name: "about-notifications-notification___hu"
  }, {
    path: "/rolunk/karrier/:job",
    component: _4fe67391,
    name: "about-career-job___hu"
  }, {
    path: "/rolunk/kepzeseink/:training",
    component: _c3fb39b6,
    name: "about-trainings-training___hu"
  }, {
    path: "/rolunk/orvosaink/:dentist",
    component: _6fae5285,
    name: "about-dentists-dentist___hu"
  }, {
    path: "/en/services/:service/:price",
    component: _2e9d235e,
    name: "services-service-price___en"
  }, {
    path: "/blog/:article",
    component: _c70e2bb0,
    name: "blog-article___hu"
  }, {
    path: "/rolunk/:content",
    component: _10fec0f0,
    name: "about-content___hu"
  }, {
    path: "/szolgaltatasok/:service",
    component: _b2951d24,
    name: "services-service___hu"
  }, {
    path: "/szolgaltatasok/:service/:price",
    component: _2e9d235e,
    name: "services-service-price___hu"
  }, {
    path: "/",
    component: _0d472714,
    name: "index___hu"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
