<template>
    <div
        :class="{
            'min-w-full lg:min-w-0 lg:absolute lg:top-0 lg:right-0 lg:h-full lg:bg-white lg:z-10 lg:transition-all shadow-md-grey lg:shadow-none':
                type === 'folding',
        }"
        :style="`width: ${type === 'folding' && isSearchbarFolded ? '50px' : 'calc(100%)'}`"
    >
        <div :class="type === 'folding' ? 'lg:relative lg:top-1/2 lg:-translate-y-1/2' : 'relative'">
            <div ref="scrollTarget" class="absolute -top-20 pointer-events-none"></div>
            <form class="relative flex items-center flex-nowrap overflow-hidden" @submit.prevent="handleSubmit">
                <button
                    v-if="type === 'folding' || type === 'standard'"
                    type="button"
                    class="
                        absolute
                        right-0
                        lg:right-auto
                        top-1/2
                        -translate-y-1/2
                        z-20
                        bg-white
                        py-1.5
                        px-2.5
                        hidden
                        lg:inline-block
                    "
                    :class="{
                        'pointer-events-none lg:left-2':
                            (type === 'folding' && !isSearchbarFolded) || type === 'standard',
                    }"
                    name="Unfold searchbar and initialize search"
                    aria-label="Unfold searchbar and initialize search"
                    @click="unfold"
                >
                    <i class="ni ni-magnifier text-xl"></i>
                </button>
                <input
                    ref="input"
                    v-model="query"
                    name="main-search"
                    class="px-4 py-4 w-full focus:outline-none z-10"
                    :class="{
                        'pr-14 lg:pl-7 lg:bg-transparent ': type === 'slim',
                        'bg-light-150 lg:pb-6': type === 'slim' && !borderless,
                        'lg:max-w-[calc(100%-200px)] lg:px-4 lg:pl-12': type === 'standard',
                        'lg:max-w-[calc(100%-200px)] lg:px-4 lg:pl-12 pr-14': type === 'folding',
                    }"
                    :placeholder="placeholder"
                    type="text"
                    autocomplete="off"
                    @input="throttledInputHandler"
                />
                <div
                    v-if="type === 'standard' || type === 'folding'"
                    class="
                        absolute
                        top-1/2
                        right-0
                        -translate-y-1/2
                        pr-4
                        lg:pr-4
                        transition-opacity
                        z-15
                        hidden
                        lg:flex
                    "
                    :class="[type === 'folding' && isSearchbarFolded ? 'opacity-0' : 'opacity-100']"
                >
                    <Button type="submit" :label="$t('generic.search')" class="mr-2" :disabled="query.length <= 2" />
                    <NuxtLink v-if="isCancelLink" class="p-button p-button-outlined" :to="localePath(`${link}`)">
                        <span @click="resetSearchInput">
                            <T t="generic.cancel" />
                        </span>
                    </NuxtLink>
                    <Button v-else class="p-button p-button-outlined" @click="resetSearchInput">
                        <T t="generic.cancel" />
                    </Button>
                </div>
                <button
                    name="Search"
                    aria-label="Search"
                    type="button"
                    class="absolute right-6 text-xl z-15"
                    :class="{
                        'pointer-events-none': !query.length,
                        'lg:hidden': type === 'standard' || type === 'folding',
                    }"
                >
                    <i v-if="!query.length" class="ni ni-magnifier"></i>
                    <NuxtLink v-if="isCancelLink && query.length" :to="localePath(`${link}`)">
                        <i class="ni ni-x-round" @click="resetSearchInput()"></i>
                    </NuxtLink>
                </button>
                <div
                    v-if="type === 'slim' && !borderless"
                    class="
                        absolute
                        bottom-0
                        left-0
                        border-b border-dark-400
                        w-full
                        max-w-[calc(100%-1rem)]
                        lg:max-w-none
                        mx-2
                        lg:mx-0
                        z-15
                    "
                ></div>
            </form>
            <div
                v-show="isSearchDropdownOpen"
                class="
                    absolute
                    w-full
                    mt-2
                    transition
                    z-20
                    bg-white
                    py-4
                    rounded-lg
                    border border-dark-400
                    flex flex-col
                    box-shadow
                    gap-2
                    max-h-72
                    overflow-y-auto
                    nicescroll
                "
            >
                <div v-if="processedResultCategories.length">
                    <div v-for="(category, i) in processedResultCategories" :key="`search-result-category-${i}`">
                        <div v-if="category.results.length > 0">
                            <div class="font-HKGrotesk-Bold py-1 px-4 mb-2" :class="{ 'mt-4': i > 0 }">
                                {{ category.label }}
                            </div>
                            {{/* pid for "problem id" */}}
                            <NuxtLink
                                v-for="(result, j) in category.results"
                                :key="`search-result-${j}`"
                                :to="result.url"
                                class="block py-1 transition-colors hover:bg-green-100 px-4"
                                @click.native="handleResultClick(result.template)"
                                v-html="result.template"
                            ></NuxtLink>
                        </div>
                        <div v-else class="px-4"><T t="generic.there_are_no_results_for_the_specified_term" /></div>
                    </div>
                </div>
                <div v-else class="px-4"><T t="generic.there_are_no_results_for_the_specified_term" /></div>
            </div>
        </div>
    </div>
</template>

<script>
import { throttle, debounce } from 'throttle-debounce';

/*
 * context: the stuff we want to search in (e.g. dental problems, blog posts etc.), not the page it is displayed on
 *
 * types are:
 * - standard:   e.g. on dental problems page, with dedicated search and clear buttons
 * - slim:       e.g. on index, has only bottom border and has no background color
 * - folding:    e.g. on blog page, has no bottom border and has no background color
 *
 * */

export default {
    name: 'SearchBlock',
    props: {
        type: {
            type: String,
            required: false,
            default: 'standard',
        },
        placeholder: {
            type: String,
            required: false,
            default: 'Keresés',
        },
        context: {
            type: String,
            required: true,
        },
        submitCb: {
            type: Function,
            required: false,
            default: () => {},
        },
        cancelCb: {
            type: Function,
            required: false,
            default: () => {},
        },
        shouldRedirectAfterSubmit: {
            type: Boolean,
            required: false,
            default: false,
        },
        focusOnMount: {
            type: Boolean,
            required: false,
            default: false,
        },
        borderless: {
            type: Boolean,
            required: false,
            default: false,
        },
        defaultQuery: {
            type: String,
            required: false,
            default: '',
        },
        isCancelLink: {
            type: Boolean,
            required: false,
            default: false,
        },
        link: {
            type: String,
            required: false,
            default: null,
        },
    },
    data() {
        return {
            query: this.defaultQuery,
            isSearchDropdownOpen: false,
            isSearchbarFolded: true,
            processedResultCategories: [],
            throttledInputHandler: throttle(300, this.handleInput),
            debouncedResizeHandler: debounce(10, this.scrollInputToTop),
        };
    },
    mounted() {
        document.addEventListener('click', this.handleClickOutside);
        if (this.focusOnMount) {
            this.$refs.input.focus();
        }
        if (this.$device.isMobileOrTablet) {
            window.addEventListener('resize', this.debouncedResizeHandler);
        }
        this.query = '';
    },
    destroyed() {
        document.removeEventListener('click', this.handleClickOutside);
        if (this.$device.isMobileOrTablet) {
            window.removeEventListener('resize', this.debouncedResizeHandler);
        }
    },
    methods: {
        transformQuery(query, string) {
            const regex = new RegExp(query, 'gi');

            // find all the matched substrings in the question's name
            const matches = string.match(regex);

            if (!matches) {
                return string;
            }

            // wrap matched substrings in <b></b> one by one
            matches.forEach((match) => {
                const _regex = new RegExp(`${match}(?!</b>)`);
                string = string.replace(_regex, `<b>$&</b>`);
            });

            return string;
        },
        handleInput() {
            if (this.query.trim().length <= 2) {
                this.isSearchDropdownOpen = false;
                return;
            }

            let _endpoint;
            const _resultCategories = [];

            // these two are only necessary for dental problem, article and work type search
            let _labelTranslateKey;
            let _categoryKey;

            switch (this.context) {
                case 'global':
                    _endpoint = '';
                    break;
                case 'dental-problems':
                    _endpoint = '/dental-problem';
                    _labelTranslateKey = 'search_result_category.dental_problems';
                    _categoryKey = 'dental_problems';
                    break;
                case 'blog':
                    _endpoint = '/article';
                    _labelTranslateKey = 'search_result_category.articles';
                    _categoryKey = 'articles';
                    break;
                case 'works':
                    _endpoint = '/reference';
                    _labelTranslateKey = 'search_result_category.references';
                    _categoryKey = 'references';
                    break;
            }

            this.$axios.$get(`${_endpoint}/search?q=${this.query.trim()}`).then((res) => {
                if (res.success === 1) {
                    if (this.context !== 'global') {
                        _resultCategories.push({
                            key: _categoryKey,
                            label: this.$t(_labelTranslateKey),
                            results: res.results,
                        });
                    } else {
                        Object.keys(res.results).forEach((categoryKey) => {
                            if (!res.results[categoryKey].length) return;
                            _resultCategories.push({
                                key: categoryKey,
                                label: this.$t('search_result_category.' + categoryKey),
                                results: res.results[categoryKey],
                            });
                        });
                    }

                    this.processedResultCategories = _resultCategories.map((category) => {
                        category.results = category.results.map((result) => {
                            const processedResult = {};

                            processedResult.id = result.id;

                            if (category.key === 'references') {
                                processedResult.template = this.transformQuery(this.query, result.title);
                                processedResult.url = this.localePath({
                                    name: 'works',
                                    query: { wid: encodeURIComponent(result.id) },
                                });
                            } else if (category.key === 'articles') {
                                processedResult.template = this.transformQuery(this.query, result.title);
                                processedResult.url = this.localePath({
                                    name: 'blog-article',
                                    params: { article: result.slug },
                                });
                            } else if (category.key === 'dental_problems') {
                                processedResult.template = this.transformQuery(this.query, result.question);
                                processedResult.url = this.localePath({
                                    name: 'dental-problems',
                                    query: { pid: encodeURIComponent(result.id) },
                                });
                            } else if (category.key === 'packages') {
                                processedResult.template = this.transformQuery(this.query, result.title);
                                processedResult.url = this.localePath({
                                    name: 'pricing',
                                    query: { pid: encodeURIComponent(result.id) },
                                });
                            } else if (category.key === 'service_categories') {
                                processedResult.template = this.transformQuery(this.query, result.title);
                                processedResult.url = this.localePath({
                                    name: 'services',
                                    query: { sid: encodeURIComponent(result.id) },
                                });
                            } else if (category.key === 'service_categories') {
                                processedResult.template = this.transformQuery(this.query, result.title);
                                processedResult.url = this.localePath({
                                    name: 'services',
                                    query: { sid: encodeURIComponent(result.id) },
                                });
                            } else if (category.key === 'services') {
                                processedResult.template = this.transformQuery(this.query, result.title);
                                processedResult.url = this.localePath({
                                    name: 'services-service',
                                    params: { service: result.slug },
                                });
                            } else if (category.key === 'service_prices') {
                                processedResult.template = this.transformQuery(this.query, result.title);
                                processedResult.url = this.localePath({
                                    name: 'services-service-price',
                                    params: { service: result.service_slug, price: result.slug },
                                });
                            } else if (category.key === 'teams') {
                                processedResult.template = this.transformQuery(this.query, result.name);
                                processedResult.url = this.localePath({
                                    name: 'about-dentists-dentist',
                                    params: { dentist: result.slug },
                                });
                            } else if (category.key === 'trainings') {
                                processedResult.template = this.transformQuery(this.query, result.title);
                                processedResult.url = this.localePath({
                                    name: 'about-trainings-training',
                                    params: { training: result.slug },
                                });
                            }

                            return processedResult;
                        });

                        return category;
                    });
                }
                // we need to do a second check just in case the form has been
                // submitted like .1s before the api response arrived
                if (this.query.trim()) {
                    this.isSearchDropdownOpen = true;
                }
            });
        },
        handleClickOutside(event) {
            if (!this.$el.contains(event.target)) {
                this.isSearchDropdownOpen = false;
                this.processedResultCategories = [];
            }
        },
        scrollInputToTop() {
            // this method positions the input field to the top of the screen on mobile devices when focused,
            // in order to not let the soft keyboard cover up the input field and the results dropdown
            if (this.$refs.input === document.activeElement) {
                this.$refs.scrollTarget.scrollIntoView({
                    behavior: 'smooth',
                });
            }
        },
        handleResultClick(result) {
            this.isSearchbarFolded = true;
            this.closeDropdown();
            this.query = result.replace(/(<([^>]+)>)/gi, '');
            this.cancelCb();
        },
        handleSubmit() {
            const _query = this.query;
            if (!_query.length) {
                this.$router.push(this.localePath({ name: 'search', query: { search: '' } }));
            }
            this.$refs.input.blur();
            this.isSearchbarFolded = true;
            this.submitCb(_query);
            if (this.shouldRedirectAfterSubmit) return;
            this.$router.push(this.localePath({ name: this.context, query: { search: encodeURIComponent(_query) } }));

            this.closeDropdown();
        },
        handleCancelClick() {
            this.isSearchbarFolded = true;
            this.query = '';
            this.closeDropdown();
            this.cancelCb();
        },
        resetSearchInput() {
            this.query = '';
            this.closeDropdown();
            this.isSearchbarFolded = true;

            this.cancelCb();
        },
        closeDropdown() {
            this.isSearchDropdownOpen = false;
            this.processedResultCategories = [];
            this.query = '';
        },

        unfold() {
            this.isSearchbarFolded = false;
            this.$refs.input.focus();
        },
    },
};
</script>

<style scoped>
.box-shadow {
    box-shadow: 0 4px 20px rgba(28, 130, 150, 0.1);
}
input {
    &::placeholder {
        @apply text-dark-500;
    }
}
</style>
